// @ts-strict-ignore
import _ from 'lodash';
import { sqItemsApi, sqWorkbooksApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { parseISODate } from '@/datetime/dateTime.utilities';

export const DEFAULT_WORKSHEET_NAME = 'worksheet';

/**
 * Get the Worksheets for a workbook.
 *
 * @param {String} workbookId - The GUID of the workbook to be fetched
 * @param {Boolean} [includeWorkstepId=false] - true to have worksheets include the current workstep ID
 * @param {Boolean} [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
 * @return {Promise} resolves with an array of worksheet objects
 */
export function getWorksheets(workbookId, includeWorkstepId = false, includeArchivedWorksheets = false) {
  // Would probably be best to integrate pagination with infinite worksheet scrolling.
  // For now, just make the limit so high that it won't be an issue.
  return sqWorkbooksApi
    .getWorksheets({ workbookId }, { params: { limit: 10000, isArchived: includeArchivedWorksheets } })
    .then(({ data }) => _.map(data.worksheets, (worksheet) => toWorksheet(worksheet, includeWorkstepId)));
}

/**
 * Gets a Worksheet.
 *
 * @param {String} workbookId - a workbook ID
 * @param {String} worksheetId - a worksheet ID
 * @param {boolean} [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
 *
 * @return {Promise} Promise that is resolved when the call completes.
 */
export function getWorksheet(workbookId, worksheetId, includeArchivedWorksheets = false) {
  return sqWorkbooksApi
    .getWorksheet({ workbookId, worksheetId }, { params: { isArchived: includeArchivedWorksheets } })
    .then((worksheet) => toWorksheet(worksheet.data));
}

/**
 * Creates a Worksheet.
 *
 * @param {String} workbookId - ID of the workbook on which to create the worksheet
 * @param {String} name - Name for the new worksheet
 * @return {Promise} resolves when the worksheet has been created
 */
export function createWorksheet(workbookId, name) {
  name = name || DEFAULT_WORKSHEET_NAME;
  return sqWorkbooksApi.createWorksheet({ name }, { workbookId }).then((worksheet) => toWorksheet(worksheet.data));
}

/**
 * Updates a worksheet's name.
 *
 * @param {String} worksheetId - GUID of the worksheet to be renamed
 * @param {String} name - The new name for the worksheet
 * @returns {Promise} - A promise that will resolve when the worksheet
 * has been successfully renamed; or reject if it was not renamed.
 */
export function setName(worksheetId, name) {
  return sqItemsApi.setProperty({ value: name }, { id: worksheetId, propertyName: SeeqNames.Properties.Name });
}

/**
 * Archives a Worksheet.
 *
 * @param {String} worksheetId - The GUID of the worksheet to be archived.
 *
 * @return {Promise} Promise that is resolved when the worksheet has been archived.
 * @example archiveWorksheet(worksheetId);
 */
export function archiveWorksheet(worksheetId) {
  return sqItemsApi.setProperty({ value: true }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
}

/**
 * Restores (unarchives) a Worksheet.
 *
 * @param {String} worksheetId - The GUID of the worksheet to be restored.
 *
 * @return {Promise} Promise that is resolved when the worksheet has been restored.
 */
export function restoreWorksheet(worksheetId) {
  return sqItemsApi.setProperty({ value: false }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
}

/**
 * Moves a Worksheet.
 *
 * @param  {String} workbookId - ID of the workbook on which the worksheet exists
 * @param  {String} worksheetId - ID of the worksheet to move
 * @param  {String} nextWorksheetId - ID of the worksheet marking the new location. If undefined, worksheet is moved
 *   to the end.
 * @return {Promise} Promise that is resolved when the call completes.
 */
export function moveWorksheet(workbookId, worksheetId, nextWorksheetId) {
  return sqWorkbooksApi.moveWorksheet({
    workbookId,
    worksheetId,
    nextWorksheetId,
  });
}

/**
 * Helper function that creates a worksheet object to be dispatched to a store
 *
 * @param {Object} worksheet - The worksheet from the API
 * @param {Boolean} [includeWorkstepId=false] - true to have worksheets include the current workstep ID
 * @returns {Object} Worksheet for a store
 */
export function toWorksheet(worksheet, includeWorkstepId = false) {
  return _.omitBy(
    {
      name: worksheet?.name,
      createdAt: worksheet && parseISODate(worksheet?.createdAt).valueOf(),
      updatedAt: worksheet && parseISODate(worksheet?.updatedAt).valueOf(),
      worksheetId: worksheet?.id,
      reportId: _.get(worksheet, 'report.id'),
      workstepId: includeWorkstepId ? worksheet.workstep : undefined,
      duplicationErrorDocument: worksheet?.duplicationErrorDocument,
    },
    _.isNil,
  );
}
